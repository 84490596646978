import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Spin, TreeSelect } from 'antd'
import { useAuth0 } from '../../auth0'
import { useAsync } from 'react-async'
import { getCategories } from '../../pages/deposit-details/api'

const CategorySelector = forwardRef(({ initialvalue, ...props }, ref) => {
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading } = useAsync({
    accessTokenPromise,
    promiseFn: getCategories,
  })

  const initialIds = initialvalue?.map(item => item.id.toString())
  const [value, setValue] = useState(initialIds)

  const transformDataToTree = (data, parentId = null) => (
    data
      .filter(item => item.parent_id === parentId)
      .map(item => ({
        children: transformDataToTree(data, item.id),
        title: item.title,
        value: item.id.toString(),
      }))
  )

  if (error) {
    return <div>An error occurred while fetching categories.</div>
  }

  if (!data || isLoading) return <Spin size="small" />

  const treeData = transformDataToTree(data?.categories)

  return (
    <TreeSelect
      allowClear
      defaultValue={initialIds}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      multiple
      onChange={newValue => setValue(newValue)}
      placeholder="Please select"
      showSearch
      style={{ width: '100%' }}
      treeData={treeData}
      treeDefaultExpandAll
      value={value}
      {...props}
    />
  )
})

CategorySelector.propTypes = {
  initialvalue: PropTypes.array,
}

export default CategorySelector
