import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-async'
import {
  Badge,
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Tree,
  Typography,
} from 'antd'
import { useAuth0 } from '../../auth0'
import { Can, ErrorMessage, Icon } from '../../components'
import { routePropTypes } from '../../types'
import {
  getCategories,
  updateCategory,
  createCategory,
  deleteCategory,
} from './api'
import CategoryModal from './CategoryModal'

import './CategoriesPage.css'

const { Title } = Typography

const CategoriesPage = () => {
  const location = useLocation()

  const [ isFormModalVisible, setFormModalVisibility ] = useState(false)
  const [ isModalLoading, setModalLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedCategory, setselectedCategory ] = useState()

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, reload } = useAsync({
    accessTokenPromise,
    promiseFn: getCategories,
    watch: location.search,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />
  const categories = (data && data.categories) || []

  const handleDeleteCategory = (recordId) => {
    deleteCategory({ accessTokenPromise, recordId })
      .then(() => {
        handleSuccess({ description: 'Category is removed successfully.' }, true)
        reload()
      })
      .catch((error) => handleFailure(error, { description: 'Category removal is failed.' }))
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updateCategory({ accessTokenPromise, fields: payload })
        .then(() => {
          handleSuccess({ description: 'Category is updated successfully.' })
          reload()
        })
        .catch((error) => handleFailure(error, { description: 'Category update is failed.' }))

    case 'CREATE':
      return createCategory({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess())
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleOpenModal = (selectedCategory) => {
    setselectedCategory(selectedCategory)
    setErrorMessage(null)
    setFormModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions = {}, isDelete = false) => {
    reload()

    setErrorMessage(null)
    setFormModalVisibility(false)

    notification.open({
      description: 'Category is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    if (error) {
      console.log(error)
    }
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Category creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const badgeStyles = {
    1: { backgroundColor: '#206AF1', count: 'Class' },
    2: { backgroundColor: '#5d7092', count: 'Category' },
    3: { backgroundColor: '#8698b9', count: 'Subcategory' },
    default: { backgroundColor: '#52c41a', count: 'Subcategory' },
  }

  const convertCategoriesToTree = (categories, parentId = null, level = 1) => {
    return categories
      .filter(category => category.parent_id === parentId)
      .map(category => ({
        ...category,
        children: convertCategoriesToTree(categories, category.id, level + 1),
        key: category.id,
        title: renderTreeNodeTitle(category, level),
      }))
  }

  const renderTreeNodeTitle = (category, level) => {
    const { count, backgroundColor } = badgeStyles[level] || badgeStyles.default
    const label = <Badge count={count} style={{ backgroundColor }} />

    return (
      <div className={`tree-node-title ${level === 1 ? 'root-category' : ''}`}>
        <span>{category.title}</span> <span style={{ marginLeft: '8px' }}>{label}</span>

        <div className="icon-wrapper">
          <Icon data-testid="updateCategorySelenium" onClick={() => handleOpenModal(category)} type="edit" />

          {!category.has_children && !category.has_barcodes && (
            <Popconfirm
              onConfirm={() => handleDeleteCategory(category.id)}
              title="Are you sure you want to delete this category?"
            >
              <Icon color="red" data-testid="deleteCategorySelenium" type="delete" />
            </Popconfirm>
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Title>Categories</Title>

      <Row justify="space-between" type="flex">
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:categories"
            yes={() => (
              <Button
                name="addNewCategorySelenium"
                onClick={() => handleOpenModal(null)}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tree
            className="categories-tree"
            showLine
            switcherIcon={<Icon type="downArrow" />}
            treeData={convertCategoriesToTree(categories)}
          />

          {isFormModalVisible &&
            <CategoryModal
              errorMessage={errorMessage}
              hideModal={() => setFormModalVisibility(false)}
              isModalLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              selectedCategory={selectedCategory}
              setModalLoading={setModalLoading}
            />
          }
        </Col>
      </Row>
    </div>
  )
}

CategoriesPage.propTypes = {
  ...routePropTypes,
}

export default CategoriesPage
