import queryString from 'query-string'
import { getHeaders, apiResponseHandler } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/suppliers'

export const createSupplier = async ({ accessTokenPromise, value }) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      name: value,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  },
).then(apiResponseHandler)

export const updateSupplier = async ({ accessTokenPromise, id, value }) => fetch(
  `${ENDPOINT_BASE_URL}/${id}`,
  {
    body: JSON.stringify({ name: value }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const deleteSupplier = async ({ accessTokenPromise, id }) => fetch(
  `${ENDPOINT_BASE_URL}/${id}`,
  {
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  },
).then(apiResponseHandler)

export const getSuppliers = async ({ accessTokenPromise, nameFilter }) => {
  const queryParams = queryString.stringify(
    {
      name: nameFilter,
    },
    {
      arrayFormat: 'bracket',
    },
  )
  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`
  const accessToken = await accessTokenPromise

  return fetch(
    endpoint,
    { headers: getHeaders(accessToken) },
  )
    .then(apiResponseHandler)
}
