import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Layout, Result } from 'antd'
import { jwtDecode } from 'jwt-decode'
import { createAuth0Client } from '@auth0/auth0-spa-js'

export const RULE_NAMESPACE = 'https://loop-integrated'

export const hasPermission = (usersPermissions, requiredPermissions) => {
  if (requiredPermissions.length === 0) return true

  return requiredPermissions.reduce((acc, currentRequiredPermission) => {
    const permissionMatch = usersPermissions.indexOf(currentRequiredPermission) !== -1

    if (permissionMatch) return true
    return acc
  }, false)
}

export const Auth0Context = React.createContext()

export const useAuth0 = () => useContext(Auth0Context)

export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [authError, setAuthError] = useState(false)
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [permissions, setPermissions] = useState()

  const [delay, setDelay] = useState(10)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)

      setAuth0(auth0FromHook)

      if (window.location.search.includes('?code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()

        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()

        setUser(user)
        const accessToken = await auth0FromHook.getTokenSilently()
        setPermissions(jwtDecode(accessToken).permissions)
      }

      setLoading(false)
    }

    initAuth0()

    if (window.location.search.includes('?error=')) {
      const errorDescription = new URL(window.location.href).searchParams.get('error_description')

      setLoading(false)
      setAuthError(errorDescription)

      const timer = setInterval(() => {
        setDelay(delay - 1)
      }, 1000)

      if (delay === 0) {
        clearInterval(timer)
        auth0Client.logout({ logoutParams: { returnTo: window.location.origin } })
      }

      return () => clearInterval(timer)
    }
    // eslint-disable-next-line
  }, [delay])

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setUser(user)
    setIsAuthenticated(true)
    const accessToken = await auth0Client.getTokenSilently()
    setPermissions(jwtDecode(accessToken).permissions)
  }

  if (authError)
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Result
          extra={<Button onClick={() => auth0Client.logout({ logoutParams: { returnTo: window.location.origin } })} type="primary">Back to login page</Button>}
          status="403"
          subTitle={`You will be automatically redirected to login screen in ${delay} seconds`}
          title={authError}
        />
      </Layout>
    )

  return (
    <Auth0Context.Provider
      value={{
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        handleRedirectCallback,
        isAuthenticated,
        loading,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => auth0Client.logout({ logoutParams: { returnTo: window.location.origin } }),
        permissions,
        user,
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

Auth0Provider.propTypes = {
  children: PropTypes.node.isRequired,
  onRedirectCallback: PropTypes.func.isRequired,
}
