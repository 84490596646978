import PropTypes from 'prop-types'
import { hasPermission, useAuth0 } from '../../auth0'

const Can = ({ yes, no, requiredPermission }) => {
  const { permissions } = useAuth0()
  const requiredPermissions = [requiredPermission]

  return hasPermission(permissions, requiredPermissions)
    ? yes()
    : no()
}

Can.defaultProps = {
  no: () => null,
  yes: () => null,
}

Can.propTypes = {
  no: PropTypes.func.isRequired,
  requiredPermission: PropTypes.string.isRequired,
  yes: PropTypes.func.isRequired,
}

export default Can
