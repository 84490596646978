import React from 'react'
import PropTypes from 'prop-types'
import { Result } from 'antd'

const ErrorMessage = ({ dataError, networkError }) => {
  if (networkError) {
    console.error('Network error:', networkError)

    return (
      <Result
        status="warning"
        subTitle={`Response: ${networkError.status} ${networkError.statusText}`}
        title="There are some problems with your operation."
      />
    )
  }

  if (dataError) {
    console.error('Data error:', dataError)

    return (
      <Result
        status="403"
        subTitle="Sorry, you are not authorized to access this page."
        title={dataError}
      />
    )
  }

  throw Error('Unhandled error')
}

ErrorMessage.propTypes = {
  dataError: PropTypes.string,
  networkError: PropTypes.object,
}

export default ErrorMessage
