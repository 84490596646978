import PropTypes from 'prop-types'
import brandPropTypes from './brand'

export default PropTypes.shape({
  brand: brandPropTypes,
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  sku: PropTypes.string,
})
