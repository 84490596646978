import PropTypes from 'prop-types'
import { barcodePropTypes } from './'

export default PropTypes.shape({
  barcodes: PropTypes.arrayOf(barcodePropTypes),
  country: PropTypes.string,
  currency: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  net_amount: PropTypes.number,
  // package: packagePropTypes,
  tax_amount: PropTypes.number,
})
