import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Form,
  Input,
  Modal,
} from 'antd'
import { supplierPropTypes, formPropTypes } from '../../types'

const SupplierModalForm = ({
  errorMessage,
  isModalLoading,
  onSubmit,
  selectedSupplier,
  setModalLoading,
  setVisibility,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedSupplier
  const { getFieldValue, isFieldTouched } = form

  const handleOk = () => {
    const isSupplierNewOrEdited = isFieldTouched('supplierName') || !isUpdate

    if (isSupplierNewOrEdited) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const name = getFieldValue('supplierName')
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedSupplier.id : undefined

          onSubmit(actionType, { fieldValue: name, id })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelSupplierModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateSupplierSelenium' : 'addSupplierSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update supplier' : 'Add a new supplier'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedSupplier.name : undefined}
          label="Supplier name"
          name="supplierName"
          rules={[{ message: 'Please add a supplier name!', required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

SupplierModalForm.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedSupplier: supplierPropTypes,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
}

export const SupplierModal = SupplierModalForm
