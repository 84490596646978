const REQUEST_HEADER = {
  accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 * Get the API request headers with or without token
 *
 * @param {string} accessToken - user's access token
 *
 * @returns {object} Headers object that can be included in fetch requests
 */
const getHeaders = (accessToken) => ({
  ...REQUEST_HEADER,
  Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
})

export default getHeaders
