import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Form, Input, InputNumber, Modal, Select, Tooltip } from 'antd'
import { formPropTypes, packagePropTypes } from '../../types'
import { ConditionSelector, Icon } from '../../components'
import { getBrands } from '../brands/api'
import { flagHelper } from './Packages'

const { TextArea } = Input
const { Option } = Select

const ModalWithForm = ({
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  selectedPackage,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedPackage

  const materialTypes = [
    { name: 'Cardboard', value: 'cardboard' },
    { name: 'Flexible Plastic', value: 'flexible_plastic' },
    { name: 'Glass', value: 'glass' },
    { name: 'Metal', value: 'metal' },
    { name: 'Multi-layered Pouches', value: 'multi_layered_pouches' },
    { name: 'Paper', value: 'paper' },
    { name: 'Rigid Plastic', value: 'rigid_plastic' },
  ]

  const reusableMaterialTypes = [
    { name: 'Stainless Steel', value: 'stainless_steel' },
    { name: 'PP', value: 'pp' },
    { name: 'PET', value: 'pet' },
    { name: 'PE', value: 'pe' },
    { name: 'Aluminium', value: 'aluminium'},
    { name: 'Glass', value: 'glass'},
  ]

  const handleOk = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = isUpdate
            ? form.getFieldsValue(['name', 'brand_id', 'sku', 'material_type', 'weight', 'recycling_rate', 'reusable_material_type', 'diverted', 'settings', 'description'].filter((field) => form.isFieldTouched(field)))
            : form.getFieldsValue(['name', 'brand_id', 'sku', 'material_type', 'weight', 'recycling_rate', 'reusable_material_type', 'diverted', 'settings', 'description'])
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedPackage.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      hideModal()
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelPackageModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updatePackageSelenium' : 'addPackageSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={hideModal}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update package' : 'Create package'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedPackage.name : undefined}
          label="Package name"
          name="name"
          rules={[{ message: 'Package Name is required!', required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? (selectedPackage.brand && selectedPackage.brand.id) : undefined}
          label="Brand"
          name="brand_id"
          rules={[{ message: 'Brand is required!', required: true }]}
        >
          <ConditionSelector dataIndex="brands" getOptionsFn={getBrands} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.sku : undefined}
          label="SKU"
          name="sku"
          rules={[
            {
              message: 'SKU is required!',
              required: true,
            },
            {
              message: 'SKU should contain only English letters and numbers!',
              pattern: new RegExp('^[A-Za-z0-9]+$'),
            },
          ]}
        >
          <Input disabled={isUpdate} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.material_type : undefined}
          label="Material of Single Use Equiv"
          name="material_type"
          rules={[{ required: false }]}
        >
          <Select>
            {materialTypes.map((material) =>
              <Option key={material.value} value={material.value}>{material.name}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.reusable_material_type : undefined}
          label="Reusable package material"
          name="reusable_material_type"
          rules={[{ required: false }]}
        >
          <Select>
            {reusableMaterialTypes.map((material) =>
              <Option key={material.value} value={material.value}>{material.name}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.weight : undefined}
          label={
            <span>Weight of Single Use Equiv&nbsp;
              <Tooltip title="Value should be between 0.01 and 99999999 or left empty.">
                <Icon type="info" />
              </Tooltip>
            </span>
          }
          name="weight"
          rules={[{ required: false }]}
        >
          <InputNumber className="packages-input-number" max={99999999.00} min={0.01} step={0.01} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.recycling_rate : undefined}
          label={
            <span>Recycling Rate&nbsp;
              <Tooltip title="Value should be between 0.00% and 100.00% or left empty.">
                <Icon type="info" />
              </Tooltip>
            </span>
          }
          name="recycling_rate"
          rules={[{ required: false }]}
        >
          <InputNumber className="packages-input-number" formatter={value => `${value}%`} max={100.00} min={0.00} parser={value => value.replace('%', '')} step={0.01} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.diverted : undefined}
          label={
            <span>Single Use Equiv Material Diverted&nbsp;
              <Tooltip title="Value should be between 0.00 and 1000.00 or left empty.">
                <Icon type="info" />
              </Tooltip>
            </span>
          }
          name="diverted"
          rules={[{ required: false }]}
        >
          <InputNumber className="packages-input-number" max={1000.00} min={0.00} step={0.01} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.settings : []}
          label="Flags"
          name="settings"
          rules={[{ required: false }]}
        >
          <Select
            allowClear
            mode="multiple"
            options={Object.keys(flagHelper).map(key => ({ label: flagHelper[key].name, value: key}))}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.description : undefined}
          label="Description"
          name="description"
        >
          <TextArea autoSize={{ maxRows: 5 , minRows: 3 }} />
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedPackage: packagePropTypes,
  setModalLoading: PropTypes.func,
}

export default ModalWithForm
