import { getHeaders, apiResponseHandler } from './api-utils'

export const CONFIG_ENDPOINT = '/api/admin/configs'

export const getConfigs = () => {
  return fetch(
    CONFIG_ENDPOINT,
    { headers: getHeaders() },
  )
    .then(apiResponseHandler)
}
