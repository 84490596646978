import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { hasPermission, useAuth0 } from '../../auth0'
import { MENU_ELEMENTS } from '../../constants'
import { routePropTypes } from '../../types'
import logo from '../../assets/images/loop-blue-logo.png'

const Sidebar = () => {
  const location = useLocation()
  const { Sider } = Layout
  const [isCollapse, setCollapse] = useState(false)
  const { permissions } = useAuth0()
  const userMenuItems = MENU_ELEMENTS.filter(menuItem => hasPermission(permissions, menuItem.permission))

  return (
    <Sider collapsed={isCollapse} collapsible onCollapse={() => setCollapse(!isCollapse)}>
      <div
        className="logoWrapper"
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <img
          alt="Loop logo"
          src={logo}
          style={{
            height: '45px',
          }}
        />
      </div>

      <Menu items={userMenuItems} key="user" mode="inline" selectedKeys={[location.pathname]} theme="dark" />
    </Sider>
  )
}

Sidebar.propTypes = {
  ...routePropTypes,
}

export default Sidebar
