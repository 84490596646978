import React, { useState } from 'react'
import orderBy from 'lodash/orderBy'
import { useAsync } from 'react-async'
import { Link, useLocation } from 'react-router-dom'
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Tag,
  Typography,
} from 'antd'
import { useAuth0 } from '../../auth0'
import { Can, CustomTable, CustomPagination, ErrorMessage, Icon } from '../../components'
import { routePropTypes } from '../../types'
import { getPackages, updatePackage, createPackage, deletePackage } from './api'
import { getBrands } from '../brands/api'
import { convertToFlatArray, getTableHeaderCheckboxFilter, getTableHeaderSearchInput, useCustomSearchParams } from '../../utils'
import PackageModal from './PackageModal'
import PackagesUploader from './PackagesUploader'
import csvSample from '../../assets/templates/import_packages.csv'
import './Packages.css'

const { Title } = Typography

export const flagHelper = {
  rejected: { color: 'red', name: 'Rejected' },
}

const PackagesPage = () => {
  const location = useLocation()
  const [searchParams] = useCustomSearchParams()

  const [ isFormModalVisible, setFormModalVisibility ] = useState(false)
  const [ isModalLoading, setModalLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedPackage, setSelectedPackage ] = useState()

  const page = searchParams.page || 1
  const itemsPerPage = searchParams.items_per_page || 25
  const brandFilter = convertToFlatArray(searchParams.brand_id)
  const nameFilter = convertToFlatArray(searchParams.name)
  const skuFilter = convertToFlatArray(searchParams.sku)
  const descriptionFilter = convertToFlatArray(searchParams.description)

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    brandFilter,
    descriptionFilter,
    itemsPerPage,
    nameFilter,
    page,
    promiseFn: getPackages,
    skuFilter,
    watch: location.search,
  })

  const brandsData = useAsync({
    accessTokenPromise,
    promiseFn: getBrands,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const brandFilters = (brandsData.data && brandsData.data.brands)
    ? orderBy(brandsData.data.brands.map(brand => ({ text: brand.name, value: brand.id })), 'text', 'asc')
    : []

  const packages = (data && data.packages) || []

  const tableColumns = [
    {
      className: 'packagesIdSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      className: 'packagesNameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.name ? [searchParams.name] : null,
      title: 'Name',
      ...getTableHeaderSearchInput('packagesNameSearchSelenium', 'name'),
    },
    {
      className: 'packagesBrandFilterSelenium',
      dataIndex: ['brand', 'name'],
      filteredValue: searchParams.brand_id || [],
      key: 'brand_id',
      title: 'Brand',
      ...getTableHeaderCheckboxFilter('packagesPageBrandFilterSelenium', brandFilters, 'brand_id', 'multiple'),
    },
    {
      className: 'packagesSkuSelenium',
      dataIndex: 'sku',
      filteredValue: searchParams.sku ? [searchParams.sku] : null,
      key: 'sku',
      title: 'SKU',
      ...getTableHeaderSearchInput('packagesSkuSearchSelenium', 'sku'),
    },
    {
      className: 'packagesFlagsSelenium',
      dataIndex: 'flags',
      key: 'flags',
      render: (_, record) => (
        <>{record.settings.map(flag => <Tag color={flagHelper[flag].color} key={flag}>{flagHelper[flag].name}</Tag>)}</>
      ),
      title: 'Flags',
    },
    {
      className: 'materialTypeSelenium',
      dataIndex: 'material_type',
      key: 'material',
      title: 'Material of Single Use Equiv',
    },
    {
      className: 'reusableMaterialTypeSelenium',
      dataIndex: 'reusable_material_type',
      key: 'reusable_material_type',
      title: 'Reusable package material',
    },
    {
      className: 'weightSelenium',
      dataIndex: 'weight',
      key: 'weight',
      title: 'Weight of Single Use Equiv',
    },
    {
      className: 'recyclingRateSelenium',
      dataIndex: 'recycling_rate',
      key: 'recyclingRate',
      title: 'Recycling Rate',
    },
    {
      className: 'divertedSelenium',
      dataIndex: 'diverted',
      key: 'diverted',
      title: 'Single Use Equiv Material Diverted',
    },
    {
      className: 'packagesDescriptionSelenium',
      dataIndex: 'description',
      filteredValue: searchParams.description ? [searchParams.description] : null,
      key: 'description',
      title: 'Description',
      ...getTableHeaderSearchInput('packagesDescriptionSearchSelenium', 'description'),
    },
    {
      className: 'packagesNumberOfDepositsSelenium',
      dataIndex: 'number_of_deposits',
      key: 'number_of_deposits',
      title: 'Number of deposits',
    },
    {
      className: 'packagesOperationsSelenium',
      dataIndex: 'operations',
      key: 'operations',
      render: (_, record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:packages"
            yes={() => (
              <Button
                className="action-list-button"
                name="updatePackageSelenium"
                onClick={() => handleOpenModal(record)}
                size="small"
              >
                  Update
              </Button>
            )}
          />

          <Can
            requiredPermission="delete:packages"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                disabled={record.number_of_deposits > 0}
                okText="Yes"
                onConfirm={() => handleDeletePackage(record.id)}
                title="Are you sure you want to delete this package?"
              >
                <Button
                  danger
                  disabled={record.number_of_deposits > 0}
                  ghost
                  name="deletePackageSelenium"
                  size="small"
                >
                    Delete
                </Button>
              </Popconfirm>
            )}
          />
          {
            record.number_of_deposits > 0 && (
              <Link
                name="showDepositLinkSelenium"
                style={{ display: 'block' }}
                to={`/deposits/?package_id=${record.id}`}
              >
                Show deposit
              </Link>
            )
          }
        </span>
      ),
      title: 'Action',
      width: 150,
    },
  ]

  const handleDeletePackage = (recordId) => {
    deletePackage({ accessTokenPromise, recordId })
      .then(() => handleSuccess({ description: 'Package is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Package removal is failed.' }))
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updatePackage({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess({ description: 'Package is updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Package update is failed.' }))

    case 'CREATE':
      return createPackage({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess({ description: 'Package is created successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Package create is failed.' }))

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleDownload = () => {
    setTimeout(() =>
      notification.open({
        description: 'Sample downloaded successfully.',
        duration: 3,
        icon: <Icon color="#52c41a" type="checkCircle" />,
        message: 'Success',
      }),
    1000)
  }

  const handleOpenModal = (selectedPackage) => {
    setSelectedPackage(selectedPackage)
    setErrorMessage(null)
    setFormModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions = {}) => {
    reload()

    setErrorMessage(null)
    setFormModalVisibility(false)

    notification.open({
      description: 'Package is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    setErrorMessage(error.message)
    setModalLoading(false)

    console.log(error)

    notification.open({
      description: 'Package creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  return (
    <div>
      <Title>Packages</Title>

      <Row justify="space-between" type="flex">
        <Col span={12} style={{ marginBottom: '20px', textAlign: 'left' }}>
          <Row type="flex">
            <Can
              requiredPermission="create:packages"
              yes={() => <PackagesUploader />}
            />

            <a
              className="download-link"
              download
              href={csvSample}
              onClick={handleDownload}
              style={{ height: '32px', lineHeight: '32px', marginLeft: '20px' }}
            >
              Download Sample&nbsp;
              <Icon type="download" />
            </a>
          </Row>
        </Col>
        <Col span={12} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:packages"
            yes={() => (
              <Button
                name="addNewPackageSelenium"
                onClick={() => handleOpenModal(null)}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            className="packagesPageTableSelenium x-scroll"
            columns={tableColumns}
            dataSource={packages}
            loading={isLoading}
          />

          <CustomPagination metaData={data?.pagination_meta} />

          {isFormModalVisible &&
            <PackageModal
              errorMessage={errorMessage}
              hideModal={() => setFormModalVisibility(false)}
              isModalLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              selectedPackage={selectedPackage}
              setModalLoading={setModalLoading}
            />
          }
        </Col>
      </Row>
    </div>
  )
}

PackagesPage.propTypes = {
  ...routePropTypes,
}

export default PackagesPage
