import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Form,
  Input,
  Modal,
  Select,
} from 'antd'
import { brandPropTypes, formPropTypes } from '../../types'
import { getSuppliers } from '../suppliers/api'
import { ConditionSelector } from '../../components'
import { flagHelper } from './BrandsPage'

const BrandModalForm = ({
  errorMessage,
  isModalLoading,
  onSubmit,
  selectedBrand,
  setModalLoading,
  setVisibility,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedBrand
  const { getFieldsValue, isFieldsTouched } = form

  const handleOk = () => {
    const isBrandNewOrEdited = isFieldsTouched() || !isUpdate

    if (isBrandNewOrEdited) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue(['name', 'settings', 'supplier_id'])
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedBrand.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelBrandModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateBrandSelenium' : 'addBrandSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update brand' : 'Add a new brand'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedBrand.name : undefined}
          label="Brand name"
          name="name"
          rules={[{ message: 'Please add a brand name!', required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue= {(isUpdate && selectedBrand.supplier) ? selectedBrand.supplier.id : undefined}
          label="Supplier"
          name="supplier_id"
        >
          <ConditionSelector data-test="suppliers" dataIndex="suppliers" getOptionsFn={getSuppliers} hasEmptyOption style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedBrand.settings : []}
          label="Flags"
          name="settings"
          rules={[{ required: false }]}
        >
          <Select
            allowClear
            data-test="flags"
            mode="multiple"
            options={Object.keys(flagHelper).map(key => ({ label: flagHelper[key].name, value: key}))}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

BrandModalForm.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedBrand: brandPropTypes,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
}

export const BrandModal = BrandModalForm
