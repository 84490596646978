import queryString from 'query-string'
import { apiResponseHandler, getHeaders } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/packages'

export const getPackages = async ({
  accessTokenPromise,
  brandFilter,
  descriptionFilter,
  itemsPerPage,
  nameFilter,
  page,
  skuFilter,
}) => {
  const queryParams = queryString.stringify(
    {
      brand_id: brandFilter,
      description: descriptionFilter,
      items_per_page: itemsPerPage,
      name: nameFilter,
      page,
      sku: skuFilter,
    },
    {
      arrayFormat: 'bracket',
    },
  )

  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`
  const accessToken = await accessTokenPromise
  return fetch(
    endpoint,
    { headers: getHeaders(accessToken) },
  )
    .then(apiResponseHandler)
}

export const updatePackage = async ({ accessTokenPromise, fields }) => (
  fetch(`${ENDPOINT_BASE_URL}/${fields.id}`, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const createPackage = async ({ accessTokenPromise, fields }) => (
  fetch(ENDPOINT_BASE_URL, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  })
).then(apiResponseHandler)

export const deletePackage = async ({ accessTokenPromise, recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}`, {
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  })
).then(apiResponseHandler)

export const importPackages = async ({ accessTokenPromise, file }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/import`

  const header = getHeaders(await accessTokenPromise)
  delete header['Content-Type']

  return fetch(endpoint, {
    body: file,
    headers: header,
    method: 'POST',
  })
    .then(apiResponseHandler)
}
