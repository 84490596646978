import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Empty, Table } from 'antd'
import { useCustomSearchParams } from '../../utils'

const StyledTable = styled(Table)`
  margin-top: 10px;

  .ant-table-row:nth-child(even) {
    background-color: #f6f6f6 !important;
  }

  thead > tr > th {
    background-color: #f0f0f0;
  }
`

const CustomTable = (props) => {
  const [sortDir, setSortDir] = useState(props.defaultOrder?.sortDir)
  const [sortBy, setSortBy] = useState(props.defaultOrder?.sortBy)

  const [search, setSearch] = useCustomSearchParams()

  const handleFilterChange = (_, __, sorter) => {
    if (sortBy && sortDir && (!sorter.column || !sorter.order)) {
      const tempParams = { ...search }

      delete tempParams.sort_by

      setSortBy(undefined)
      setSortDir(undefined)
      setSearch({ ...tempParams })
    } else if (sortBy !== sorter.column?.dataIndex || sortDir !== sorter.order) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC'

      setSortBy(sorter.column.dataIndex)
      setSortDir(sorter.order)
      setSearch({ ...search, items_per_page: props.metaData.items_per_page, page: props.metaData.page, sort_by: `${sorter.column.dataIndex} ${sortDirection}` })
    }
  }

  return (
    <StyledTable
      {...props}
      bordered
      locale={{ emptyText: <Empty description="No data to show" /> }}
      onChange={handleFilterChange}
      pagination={props.pagination || false}
      rowKey={props.rowKey || 'id'}
      scroll={{ x: true }}
    />
  )
}

CustomTable.defaultProps = {
  isModalTable: false,
  metaData: {
    items_per_page: 25,
    page: 1,
  },
}

CustomTable.propTypes = {
  defaultOrder: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
  }),
  isModalTable: PropTypes.bool,
  metaData: PropTypes.object,
  pagination: PropTypes.object,
  rowKey: PropTypes.string,
}

export default CustomTable
