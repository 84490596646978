import React, { useState } from 'react'
import { useAsync } from 'react-async'
import { Link, useLocation } from 'react-router-dom'
import {
  Button,
  Col,
  notification,
  Row,
  Typography,
} from 'antd'
import { createRetailer, getRetailers, updateRetailer } from './api'
import { Can, CustomTable, ErrorMessage, Icon } from '../../components'
import { routePropTypes } from '../../types'
import { convertToFlatArray, getTableHeaderSearchInput } from '../../utils'
import { useAuth0 } from '../../auth0'
import RetailersModal from './RetailersModal'

const { Title } = Typography

const Retailers = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [ isRetailerModalVisible, setRetailerModalVisibility ] = useState(false)
  const [ isModalLoading, setModalLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedRetailer, setSelectedRetailer ] = useState(null)
  const nameFilter = convertToFlatArray(queryParams.get('name'))
  const sortBy = queryParams.get('sort_by') || 'name'
  const page = parseInt(queryParams.get('page'), 10) || 1
  const itemsPerPage = parseInt(queryParams.get('items_per_page'), 10) || 25

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    itemsPerPage,
    nameFilter,
    page,
    promiseFn: getRetailers,
    sortBy,
    watch: location.search,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const retailers = (data && data) || []

  const handleSuccess = (messageOptions = {}) => {
    reload()
    setErrorMessage(null)
    setRetailerModalVisibility(false)

    notification.open({
      description: 'Retailer is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    setErrorMessage(error.message)
    setModalLoading(false)
    console.log(error)

    notification.open({
      description: 'Retailer creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updateRetailer({
        accessTokenPromise,
        fields: {
          name: payload.retailerName,
        },
        id: payload.id,
      })
        .then(() => handleSuccess({
          description: 'Retailer is updated successfully',
        }))
        .catch((error) =>
          handleFailure(error, { description: 'Retailer update is failed.' }),
        )

    case 'CREATE':
      return createRetailer({
        accessTokenPromise,
        fields: {
          name: payload.retailerName,
        },
      })
        .then(handleSuccess)
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleAddClick = () => {
    setSelectedRetailer(null)
    setErrorMessage(null)
    setRetailerModalVisibility(true)
    setModalLoading(false)
  }

  const handleUpdateClick = (retailer) => {
    setSelectedRetailer(retailer)
    setErrorMessage(null)
    setRetailerModalVisibility(true)
    setModalLoading(false)
  }

  const columns = [
    {
      className: 'retailersIdSelenium',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      title: 'ID',
      width: 70,
    },
    {
      className: 'retailersNameSelenium',
      dataIndex: 'name',
      filteredValue: queryParams.getAll('name'),
      key: 'name',
      sorter: true,
      title: 'Name',
      ...getTableHeaderSearchInput('retailersNameSearchSelenium', 'name'),
      width: 250,
    },
    {
      className: 'numberOfDepositsSelenium',
      dataIndex: 'number_of_deposits',
      key: 'number_of_deposits',
      render: (_, record) => (
        (record.number_of_deposits === 0) ?
          '-' :
          <Link to={`/deposits?retailer_id=${record.id}`}>{record.number_of_deposits}</Link>
      ),
      title: 'Number of connected deposits',
      width: 350,
    },
    {
      className: 'retailersPageActionSelenium',
      key: 'action',
      render: (record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:retailers"
            yes={() => (
              <Button
                className="action-list-button"
                name="updateRetailerSelenium"
                onClick={() => handleUpdateClick(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />
        </span>
      ),
      title: 'Action',
      width: 100,
    },
  ]

  return (
    <div>
      <Title>Retailers</Title>
      <Row>
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:retailers"
            yes={() => (
              <Button name="addNewRetailerSelenium" onClick={handleAddClick} type="primary">
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            className="retailersPageTableSelenium x-scroll"
            columns={columns}
            dataSource={retailers}
            loading={isLoading}
          />

        </Col>
      </Row>

      {isRetailerModalVisible && (
        <RetailersModal
          errorMessage={errorMessage}
          hideModal={() => setRetailerModalVisibility(false)}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedRetailer={selectedRetailer}
          setModalLoading={setModalLoading}
        />
      )}
    </div>
  )
}

Retailers.propTypes = {
  ...routePropTypes,
}

export default Retailers
