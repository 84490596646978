import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Form,
  Input,
  Modal,
} from 'antd'
import { retailerPropTypes, formPropTypes } from '../../types'

const RetailersModal = ({
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  selectedRetailer,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedRetailer
  const { getFieldsValue, isFieldsTouched } = form

  const handleOk = () => {
    const isRetailerNewOrEdited = isFieldsTouched() || !isUpdate

    if (isRetailerNewOrEdited) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue()
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedRetailer.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      hideModal()
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelRetailerModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateRetailerSelenium' : 'addRetailerSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={hideModal}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update retailer' : 'Add a new retailer'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedRetailer.name : undefined}
          label="Retailer name"
          name="retailerName"
          rules={[{ message: 'Please add a retailer name!', required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

RetailersModal.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedRetailer: retailerPropTypes,
  setModalLoading: PropTypes.func.isRequired,
}

export default RetailersModal
