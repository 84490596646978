import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import { useAsync } from 'react-async'
import { Alert, Form, Input, Modal, Select } from 'antd'
import { useAuth0 } from '../../auth0'
import { categoryPropTypes, formPropTypes } from '../../types'
import { getCategories } from './api'

const { Option } = Select

const CategorySelector = forwardRef((props, ref) => {
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()
  const { data, error, isLoading } = useAsync({
    accessTokenPromise,
    promiseFn: getCategories,
  })

  if (error) {
    console.log(error)
    return <div>An error occurred while fetching categories.</div>
  }

  if (!data)
    return null

  const dataValues = data ? orderBy(data.categories, 'title', 'asc') : []

  return (
    <Select
      allowClear
      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      loading={isLoading}
      ref={ref}
      showSearch
      {...props}
    >
      {dataValues.map((element) =>
        <Option
          key={element.id}
          name="categoryOption"
          value={element.id}
        >
          {element.title}
        </Option>,
      )}
    </Select>
  )
})

const ModalWithForm = ({
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  selectedCategory,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const { getFieldsValue, isFieldsTouched } = form
  const isUpdate = !!selectedCategory

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue([
            'id',
            'title',
            'parent_id',
          ])
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedCategory.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      hideModal()
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelCategoryModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateCategorySelenium' : 'addCategorySelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={hideModal}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update category' : 'Create category'}
    >
      <Form form={form} layout="vertical">
        {selectedCategory?.id &&
          <Form.Item
            initialValue={isUpdate ?  selectedCategory.id : undefined}
            label="Category Id"
            name="id"
          >
            <Input disabled />
          </Form.Item>
        }

        <Form.Item
          initialValue={isUpdate ? selectedCategory.title : undefined}
          label="Title"
          name="title"
          rules={[{ message: 'Title is required!', required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ?  selectedCategory.parent_id : undefined}
          label="Parent Category"
          name="parent_id"
        >
          <CategorySelector />
        </Form.Item>
      </Form>

      {errorMessage &&
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          type="error"
        />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedCategory: categoryPropTypes,
  setModalLoading: PropTypes.func.isRequired,
}

export default ModalWithForm
