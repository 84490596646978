import React, { useState } from 'react'
import { useAsync } from 'react-async'
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Tag,
  Typography,
} from 'antd'
import orderBy from 'lodash/orderBy'
import { useLocation } from 'react-router-dom'
import { createBrand, deleteBrand, getBrands, updateBrand } from './api'
import { getSuppliers } from '../suppliers/api'
import { BrandModal } from './BrandModal'
import { Can, CustomTable, CustomPagination, ErrorMessage, Icon } from '../../components'
import { routePropTypes } from '../../types'
import { convertToFlatArray, getTableHeaderCheckboxFilter, getTableHeaderSearchInput, useCustomSearchParams } from '../../utils'
import { useAuth0 } from '../../auth0'
import './BrandsPage.css'

const { Title } = Typography

export const flagHelper = {
  private_label: { color: 'red', name: 'PrivateLabel'},
}

const Brand = () => {
  const location = useLocation()
  const [searchParams] = useCustomSearchParams()

  const [ isBrandModalVisible, setBrandModalVisibility ] = useState(false)
  const [ isModalLoading, setModalLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedBrand, setSelectedBrand ] = useState(null)

  const page = searchParams.page || 1
  const nameFilter = convertToFlatArray(searchParams.name)
  const sortBy = searchParams.sort_by || 'name'
  const supplierFilter = convertToFlatArray(searchParams.supplier)
  const itemsPerPage = searchParams.items_per_page|| 25

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    itemsPerPage,
    nameFilter,
    page,
    promiseFn: getBrands,
    sortBy,
    supplierFilter,
    watch: location.search,
  })

  const suppliersData = useAsync({
    accessTokenPromise,
    promiseFn: getSuppliers,
  })

  const supplierFilters = (suppliersData.data && suppliersData.data.suppliers)
    ? orderBy(suppliersData.data.suppliers.map(supplier => ({ text: supplier.name, value: supplier.id })), 'text', 'asc')
    : []

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const brands = (data && data.brands) || []

  const handleSuccess = (messageOptions = {}) => {
    reload()
    setErrorMessage(null)
    setBrandModalVisibility(false)

    notification.open({
      description: 'Brand is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    setErrorMessage(error.message)
    setModalLoading(false)
    console.log(error)

    notification.open({
      description: 'Brand creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updateBrand({
        accessTokenPromise,
        fields: payload,
        id: payload.id,
      })
        .then(() => handleSuccess({
          description: 'Brand is updated successfully',
        }))
        .catch((error) =>
          handleFailure(error, { description: 'Brand update is failed.' }),
        )

    case 'CREATE':
      return createBrand({
        accessTokenPromise,
        fields: payload,
      })
        .then(handleSuccess)
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleAddClick = () => {
    setSelectedBrand(null)
    setErrorMessage(null)
    setBrandModalVisibility(true)
    setModalLoading(false)
  }

  const handleUpdateClick = (brand) => {
    setSelectedBrand(brand)
    setErrorMessage(null)
    setBrandModalVisibility(true)
    setModalLoading(false)
  }

  const handleRemoveClick = (id) => {
    deleteBrand({ accessTokenPromise, id })
      .then(() => handleSuccess({ description: 'Brand is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Brand removal is failed.' }))
  }

  const columns = [
    {
      className: 'brandIdSelenium',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      title: 'ID',
    },
    {
      className: 'brandsNameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.name ? [searchParams.name] : null,
      key: 'name',
      sorter: true,
      title: 'Name',
      ...getTableHeaderSearchInput('brandsNameSearchSelenium', 'name'),
    },
    {
      className: 'numberOfPackagesSelenium',
      dataIndex: 'number_of_packages',
      key: 'number_of_packages',
      title: 'Number of packages',
    },
    {
      className: 'packagesFlagsSelenium',
      dataIndex: 'flags',
      key: 'flags',
      render: (_, record) => (
        <>{record.settings?.map(flag => <Tag color={flagHelper[flag].color} key={flag}>{flagHelper[flag].name}</Tag>)}</>
      ),
      title: 'Flags',
    },
    {
      className: 'brandsPageSupplierNameSelenium',
      dataIndex: ['supplier', 'name'],
      filteredValue: searchParams.supplier || null,
      key: 'supplier',
      title: 'Supplier',
      ...getTableHeaderCheckboxFilter('brandsPageSupplierFilterSelenium', supplierFilters, 'supplier', 'multiple'),
    },
    {
      className: 'brandsPageActionSelenium',
      key: 'action',
      render: (record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:brands"
            yes={() => (
              <Button
                className="action-list-button"
                name="updateBrandSelenium"
                onClick={() => handleUpdateClick(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />

          <Can
            requiredPermission="delete:brands"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                disabled={record.number_of_packages > 0}
                okText="Yes"
                onConfirm={() => handleRemoveClick(record.id)}
                title="Are you sure you want to delete this brand?"
              >
                <Button
                  danger
                  disabled={record.number_of_packages > 0}
                  ghost
                  name="deleteBrandSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
      width: 150,
    },
  ]

  return (
    <div>
      <Title>Brands</Title>
      <Row>
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:brands"
            yes={() => (
              <Button name="addNewBrandSelenium" onClick={handleAddClick} type="primary">
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            className="brandsPageTableSelenium x-scroll"
            columns={columns}
            dataSource={brands}
            loading={isLoading}
            metaData={data?.pagination_meta}
          />

          <CustomPagination metaData={data?.pagination_meta} />
        </Col>
      </Row>

      {isBrandModalVisible && (
        <BrandModal
          addBrand={handleAddClick}
          errorMessage={errorMessage}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedBrand={selectedBrand}
          setModalLoading={setModalLoading}
          setVisibility={setBrandModalVisibility}
        />
      )}
    </div>
  )
}

Brand.propTypes = {
  ...routePropTypes,
}

export default Brand
