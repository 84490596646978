import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import { useAsync } from 'react-async'
import { Select } from 'antd'
import { useAuth0 } from '../../auth0'

const { Option } = Select

const ConditionSelector = forwardRef(({ dataIndex, getOptionsFn, hasEmptyOption, ...props}, ref) => {
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading } = useAsync({
    accessTokenPromise,
    promiseFn: getOptionsFn,
  })

  if (error) {
    console.log(error)
  }

  const dataValues = data && data[dataIndex] ? orderBy(data[dataIndex], 'name', 'asc') : []

  return (
    <Select loading={isLoading} ref={ref} {...props}>
      {
        hasEmptyOption &&
        <Option value={''}>-not selected-</Option>
      }
      {dataValues.map((condition) =>
        <Option key={condition.id} value={condition.id}>{condition.name}</Option>,
      )}
    </Select>
  )
})

ConditionSelector.propTypes = {
  dataIndex: PropTypes.string.isRequired,
  getOptionsFn: PropTypes.func.isRequired,
  hasEmptyOption: PropTypes.bool,
}

export default ConditionSelector
