import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import orderBy from 'lodash/orderBy'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAsync } from 'react-async'
import {
  Button,
  Col,
  Input,
  notification,
  Popconfirm,
  Row,
  Typography,
} from 'antd'
import { useAuth0 } from '../../auth0'
import { Can, CustomPagination, CustomTable, ErrorMessage, Icon } from '../../components'
import { routePropTypes } from '../../types'
import { convertToFlatArray, getTableHeaderCheckboxFilter, getTableHeaderSearchInput, useCustomSearchParams } from '../../utils'
import {
  getDeposits,
  getCountries,
  getCurrencies,
  updateDeposit,
  createDeposit,
  deleteDeposit,
} from './api'
import { getBrands } from '../brands/api'
import { getRetailers } from '../retailers/api'
import DepositModal from './DepositModal'
import './DepositsPage.css'

const { Search } = Input
const { Title } = Typography

const DepositsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useCustomSearchParams()

  const page = searchParams.page || 1
  const itemsPerPage = searchParams.items_per_page || 25

  const barcodeFilter = searchParams.barcode || null
  const brandFilter = convertToFlatArray(searchParams.brand_id)
  const countryFilter = convertToFlatArray(searchParams.country)
  const currencyFilter = convertToFlatArray(searchParams.currency)
  const packageNameFilter = convertToFlatArray(searchParams.package_name)
  const retailerIdFilter = convertToFlatArray(searchParams.retailer_id)
  const packageIdFilter = convertToFlatArray(searchParams.package_id)

  const [ isFormModalVisible, setFormModalVisibility ] = useState(false)
  const [ isModalLoading, setModalLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedDeposit, setSelectedDeposit ] = useState()
  const [ barcodeValue, setBarcodeValue ] = useState(barcodeFilter || null)

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    barcodeFilter,
    brandFilter,
    countryFilter,
    currencyFilter,
    itemsPerPage,
    packageIdFilter,
    packageNameFilter,
    page,
    promiseFn: getDeposits,
    retailerIdFilter,
    watch: location.search,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const brandsData = useAsync({
    accessTokenPromise,
    promiseFn: getBrands,
  })

  const countries = useAsync({
    accessTokenPromise,
    promiseFn: getCountries,
  })

  const currencies = useAsync({
    accessTokenPromise,
    promiseFn: getCurrencies,
  })

  const retailersData = useAsync({
    accessTokenPromise,
    promiseFn: getRetailers,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const brandFilters = (brandsData.data && brandsData.data.brands)
    ? orderBy(brandsData.data.brands.map(brand => ({ text: brand.name, value: brand.id })), 'text', 'asc')
    : []

  const retailerFilters = retailersData.data
    ? orderBy(retailersData.data.map(retailer => ({ text: retailer.name, value: retailer.id })), 'text', 'asc')
    : []

  const countryFilters = countries.data
    ? countries.data.sort().map(country => ({ text: country, value: country }))
    : []

  const currencyFilters = currencies.data
    ? currencies.data.sort().map(currency => ({ text: currency, value: currency }))
    : []

  const deposits = (data && data.deposits) || []

  const tableColumns = [
    {
      className: 'depositIdSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      className: 'depositPackageNameSelenium',
      filteredValue: searchParams.package_name ? [searchParams.package_name] : null,
      key: 'package_name',
      render: (_, record) => (
        <Link to={`/deposits/${record.id}`}>{record.package.name}</Link>
      ),
      title: 'Package',
      ...packageIdFilter.length === 0 && getTableHeaderSearchInput('depositPackageNameSearchSelenium', 'package_name'),
    },
    {
      className: 'depositBrandNameSelenium',
      dataIndex: ['package', 'brand', 'name'],
      filteredValue: searchParams.brand_id || [],
      key: 'brand_id',
      title: 'Brand',
      ...getTableHeaderCheckboxFilter('depositBrandNameFilterSelenium', brandFilters, 'brand_id', 'multiple'),
    },
    {
      className: 'depositCountrySelenium',
      dataIndex: 'country',
      filteredValue: searchParams.country || [],
      key: 'country',
      title: 'Country',
      ...getTableHeaderCheckboxFilter('depositCountryFilterSelenium', countryFilters, 'country', 'multiple'),
    },
    {
      className: 'depositNetPriceSelenium',
      dataIndex: 'net_amount',
      key: 'net_amount',
      title: 'Net price',
    },
    {
      className: 'depositTaxSelenium',
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      title: 'VAT/TAX',
    },
    {
      className: 'depositCurrencySelenium',
      dataIndex: 'currency',
      filteredValue: searchParams.currency || [],
      key: 'currency',
      title: 'Currency',
      ...getTableHeaderCheckboxFilter('depositCurrencyFilterSelenium', currencyFilters, 'currency', 'multiple'),
    },
    {
      className: 'retailersSelenium',
      dataIndex: 'retailers',
      filteredValue: searchParams.retailer_id || null,
      key: 'retailer_id',
      render: (_, record) => (
        record.retailers?.map((field) => (
          <p key={field.id}>{field.name}</p>
        ))
      ),
      title: 'Retailers',
      ...getTableHeaderCheckboxFilter('depositRetailerNameFilterSelenium', retailerFilters, 'retailer_id', 'multiple'),
    },
    {
      className: 'depositActionSelenium',
      key: 'action',
      render: (_, record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:deposits"
            yes={() => (
              <Button
                className="action-list-button"
                name="updateDepositSelenium"
                onClick={() => handleOpenModal(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />

          <Can
            requiredPermission="delete:deposits"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                okText="Yes"
                onConfirm={() => handleDeleteDeposit(record.id)}
                title="Are you sure you want to delete this deposit?"
              >
                <Button
                  danger
                  ghost
                  name="deleteDepositSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
    },
  ]

  const handleDeleteDeposit = (recordId) => {
    deleteDeposit({ accessTokenPromise, recordId })
      .then(() => handleSuccess({ description: 'Deposit is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Deposit removal is failed.' }))
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updateDeposit({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess({ description: 'Deposit is updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Deposit update is failed.' }))

    case 'CREATE':
      return createDeposit({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess())
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleOpenModal = (selectedDeposit) => {
    setSelectedDeposit(selectedDeposit)
    setErrorMessage(null)
    setFormModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions = {}) => {
    reload()

    setErrorMessage(null)
    setFormModalVisibility(false)

    notification.open({
      description: 'Deposit is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    if (error) {
      console.log(error)
    }
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Deposit creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleBarCodeSearch = (value) => {
    setBarcodeValue(value)

    const queryStringObject = Object.assign(
      {},
      searchParams,
      { barcode: value || [] },
    )

    navigate({
      ...location,
      search: queryString.stringify(queryStringObject),
    })
  }

  return (
    <div>
      <Title>Deposits</Title>

      <Row
        justify="space-between"
        style={{marginBottom: '12px'}}
        type="flex"
      >
        <Col name="barcodeSearchSelenium" span={8}>
          <Search
            allowClear
            defaultValue={barcodeValue}
            enterButton
            onSearch={handleBarCodeSearch}
            placeholder="Search barcode"
            style={{
              width: '20rem',
            }}
          />
        </Col>

        <Col
          offset={8}
          span={8}
          style={{ textAlign: 'right' }}
        >
          <Can
            requiredPermission="create:deposits"
            yes={() => (
              <Button
                name="addNewDepositSelenium"
                onClick={() => handleOpenModal(null)}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            className="depositsPageTableSelenium x-scroll"
            columns={tableColumns}
            dataSource={deposits}
            loading={isLoading}
            metaData={data?.pagination_meta}
            rowKey="id"
          />

          <CustomPagination metaData={data?.pagination_meta} />

          {isFormModalVisible &&
            <DepositModal
              countryList={countries.data}
              currencyList={currencies.data}
              errorMessage={errorMessage}
              hideModal={() => setFormModalVisibility(false)}
              isModalLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              selectedDeposit={selectedDeposit}
              selectedPackageId={packageIdFilter[0] && deposits[0]?.package.id}
              selectedRetailers={selectedDeposit?.retailers}
              setModalLoading={setModalLoading}
            />
          }
        </Col>
      </Row>
    </div>
  )
}

DepositsPage.propTypes = {
  ...routePropTypes,
}

export default DepositsPage
