import queryString from 'query-string'
import { apiResponseHandler, getHeaders } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/retailers'

export const createRetailer = async ({ accessTokenPromise, fields }) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  },
).then(apiResponseHandler)

export const updateRetailer = async ({ accessTokenPromise, fields, id }) => fetch(
  `${ENDPOINT_BASE_URL}/${id}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const getRetailers = async ({
  accessTokenPromise,
  nameFilter,
  page,
  sortBy,
  itemsPerPage,
}) => {
  const queryParams = queryString.stringify(
    {
      items_per_page: itemsPerPage,
      name: nameFilter,
      page,
      sort_by: sortBy,
    },
    {
      arrayFormat: 'bracket',
    },
  )
  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`
  const accessToken = await accessTokenPromise

  return fetch(
    endpoint,
    { headers: getHeaders(accessToken) },
  )
    .then(apiResponseHandler)
}
